<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title><a @click="goToList">{{ entity.title }}</a>&nbsp;-&nbsp;{{ product.title }}</v-card-title>
                <v-card-text>
                    <ProductTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-row form class="form-group">
                        <v-col sm="3">Категория</v-col>
                        <v-col sm="9">{{ productCategory }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Название</v-col>
                        <v-col sm="9">{{ product.title }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Внешний ID</v-col>
                        <v-col sm="9">{{ product.external_id }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Цена</v-col>
                        <v-col sm="9">{{ priceFormated(product.price) }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Вес</v-col>
                        <v-col sm="9">{{ weightFormated(product.weight) }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Картинка</v-col>
                        <v-col sm="9"><v-img width="300px" v-if="product.picture" :src="imageSrc(product.picture)" /></v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Описание</v-col>
                        <v-col sm="9">{{ product.description }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Состав</v-col>
                        <v-col sm="9">{{ product.composition }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Популярный</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(product.enabled)}`">
                                &nbsp;{{product.enabled ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Позиция</v-col>
                        <v-col sm="9">{{ product.position }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Активен</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(product.enabled)}`">
                                &nbsp;{{product.enabled ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">На доставку</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(product.for_delivery)}`">
                                &nbsp;{{product.for_delivery ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">В электронном меню</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(product.for_menu)}`">
                                &nbsp;{{product.for_menu ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goToList"
                        >К списку</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import ProductTabLinks from '../../components/sites/ProductTabLinks';

    import { imageSrc, priceFormated, weightFormated } from '../../helpers';

    export default {
        name: 'ProductDetail',

        components: {
            ProductTabLinks,
        },

        data() {
            return {
                imageSrc,
                priceFormated,
                weightFormated,
                deleteModal: false,
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('siteTypes', {
                siteTypes: state => state.entities
            }),
            ...mapState('sites', {
                entity: state => state.entity
            }),
            ...mapState('categories', {
                categories: state => state.entities
            }),
            ...mapState('products', {
                product: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            },
            productCategory() {
                const type = this.categories.find(item => item.id === this.product.category_id);
                return type ? type.title : '';
            }
        },

        async mounted() {
            const { site_id, id } = this.$route.params;
            await store.dispatch('siteTypes/fetch');
            if(site_id) {
                await store.dispatch('sites/get', {id: site_id});
                await store.dispatch('categories/fetch', {site_id});
            }
            if(site_id && id) {
                await store.dispatch('products/get', {site_id, id});
            }
        },

        methods: {
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goToList() {
                const { site_id } = this.$route.params;
                if(site_id) {
                    this.$router.push(`/sites/${site_id}/products`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>